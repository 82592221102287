<template>
<div class="wizard__fields-container sw-form">
  <div v-for="(field, k) in fields" :key="k">
    <inquiry-field :field="field" :marketingType="marketingType" filled @update="update"></inquiry-field>
  </div>
</div>
</template>

<script>
import InquiryField from "./InquiryField";

export default {
  name: "InquiryFields",

  components: {
    InquiryField
  },

  props: [
    "fields",
    "marketingType"
  ],

  methods: {
    update(event) {
      this.$emit("fieldChanged", event);
    }
  }
};
</script>
