<template>
<v-app>
  <app-loading :modal="!standalone"></app-loading>
  <slot v-if="appLoaded"></slot>
</v-app>
</template>

<script>
import AppLoading from "./AppLoading.vue";

export default {
  name: "AppWrapper",

  components: {
    AppLoading
  },

  props: {
    standalone: Boolean
  },

  computed: {
    appLoaded() {
      return this.$store.state.appLoaded;
    }
  }
};
</script>

