import depsloader from "../depsloader";
import Vue from "vue";
import vuetify from "../vuetify.js";

function loadStore() {
  return new Promise(function(resolve) {
    import(/* webpackChunkName: "store" */ "../store/index.js").then(res => {
      resolve(res.default);
    });
  });
}

function loadRoutes() {
  return new Promise(function(resolve) {
    import(/* webpackChunkName: "immoapp-routes" */ "../routes/immoapp.js").then(res => {
      resolve(res.default);
    });
  });
}

export default function (element, app, opts) {
  let loadingElement = element.parentNode;

  const deps = [];

  deps.push(depsloader.load("vue-filters"));
  deps.push(depsloader.load("vuex"));
  deps.push(depsloader.load("vue-i18n"));

  const options = {
    router: "router" in opts && opts.router,
    store: "store" in opts && opts.store,
    props: "props" in opts && opts.props
  };

  if (options.store) {
    deps.push(loadStore())
  } else {
    deps.push(Promise.resolve());
  }

  if (options.router) {
    deps.push(loadRoutes())
  } else {
    deps.push(Promise.resolve());
  }

  Promise.all(deps).then(res => {
    const Filters = res[0];
    const VuexInstaller = res[1];
    const vueI18n = res[2];
    const store = res[3];
    const routes = res[4];

    Vue.use(Filters);
    Vue.use(vueI18n.VueI18n);

    const args = {
      el: element,
      template: element,
      vuetify: vuetify,
      i18n: new vueI18n.VueI18n(vueI18n.options),
    };

    if (options.store) {
      let router = null;
      if (options.router) {
        router = {
          routes: routes,
          mode: "history",
          base: "/app",
          scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
              return savedPosition;
            }

            return null;
          }
        };
      }

      const vuex = VuexInstaller({
        Vue: Vue,
        store: store,
        router: router ? router : null
      });


      args.store = vuex.store;

      if (router) {
        args.router = vuex.router;
      }
    }

    if (options.props) {
      args.render = h => h(app, { props: options.props });
    } else {
      args.render = h => h(app);
    }

    args.computed = {
      loading() {
        return this.$store.state.loading;
      },
      appLoaded() {
        return this.$store.state.appLoaded;
      }
    };

    args.created = function() {
      if (loadingElement) {
        loadingElement.classList.add("done");
      }

      // initialize
      const ds = document.querySelector("html").dataset;
      if ("immoappLoginError" in ds && ds.immoappLoginError == "true") {
        this.$store.commit("auth/loginError", true);
      }
      if (this.$store) {
        if ("immoappLoginToken" in ds && ds.immoappLoginToken && ds.immoappLoginToken.toLocaleLowerCase() != "null") {
          this.$store.dispatch("auth/auth", ds.immoappLoginToken);
        } else {
          this.$store.dispatch("appLoaded");
        }
      }
    };

    new Vue(args);
  });
}
