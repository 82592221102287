<template>
<app-wrapper>
  <v-alert v-if="loginError" :value="true" type="error">{{ $t('Während der Authentifizierung ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.') }}</v-alert>
  <immoapp-login v-else>
    <div v-if="init">
      <v-alert v-if="apps === null" :value="true" type="warning">{{ $t('Daten konnte nicht vollständig geladen werden.') }}</v-alert>
      <router-view v-else></router-view>
    </div>
  </immoapp-login>
</app-wrapper>
</template>

<script>
import AppWrapper from "./AppWrapper";
import ImmoappLogin from "./ImmoappLogin";

export default {
  name: "Immoapp",

  components: {
    AppWrapper,
    ImmoappLogin
  },

  data() {
   return {
     init: false
   };
  },

  created() {
    this.$store.dispatch("auth/apps").then(() => {
      this.init = true;
    });
  },

  computed: {
    loginError() {
      return this.$store.state.auth.loginError;
    },

    username() {
      return this.$store.state.user.email;
    },

    apps() {
      return this.$store.state.auth.apps;
    }
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
