import ImmoappLoader from "../misc/ImmoappLoader";
import LeadWizardStandaloneApp from "../components/immoapp/contact/LeadWizardStandaloneApp";

export default function() {
  const element = document.querySelector("#vue-immoapp-leadwizard");
  if (!element) {
    return;
  }

  ImmoappLoader(element, LeadWizardStandaloneApp, {
    store: true
  });
}

