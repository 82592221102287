<template>
<app-wrapper :standalone="true">
  <lead-wizard></lead-wizard>
</app-wrapper>
</template>

<script>
import AppWrapper from "../AppWrapper";
import LeadWizard from "./LeadWizard";

export default {
  name: "LeadWizardStandaloneApp",

  components: {
    AppWrapper,
    LeadWizard
  }
};
</script>
