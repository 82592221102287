import depsloader from "../depsloader";

export default () => {
	depsloader.load("js-cookie").then((Cookie) => {
		const ctaWrapper = document.querySelector('.sw-cta-dybeck__outer-wrapper');

		if (! Cookie.get('sw_dybeck_wertermittlung_closed')) {
			setTimeout(() => {
				ctaWrapper.dataset.show = 'true';
				ctaWrapper.style.animation = 'fadeIn 0.5s ease-in-out';
			}, 5000);
		}

		const closeBtn = ctaWrapper.querySelector('.sw-cta-dybeck__close-button');
		closeBtn.addEventListener('click', () => {
			ctaWrapper.dataset.show = 'false';
			Cookie.set('sw_dybeck_wertermittlung_closed', 'true', { expires: 3 });
		});
	});


}