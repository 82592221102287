<template>
<div class="maps-expose">
  <standard-map v-model="map" :value="mapCenter" :zoom="mapZoom" @initialized="onMapLoad" height="25rem"></standard-map>
</div>
</template>

<script>
import StandardMap from "../StandardMap";

export default {
  name: "ImmoExposeMap",

  components: {
    StandardMap
  },

  data() {
    return {
      map: null
    }
  },

  computed: {
    mapCenter() {
      return this.$root.mapCenter;
    },

    mapZoom() {
      return this.$root.mapZoom;
    }
  },

  methods: {
    onMapLoad(o) {
      window._mapMarkers.forEach((elm) => {
        o.L.marker({
          lat: elm.lat,
          lng: elm.lng
        }).bindPopup(elm.html)
        .addTo(o.map);
      });

      this.map = this.mapCenter;
      o.L.control.zoom({
        position: 'bottomleft'
      }).addTo(o.map);
      o.map.zoomControl.remove();
      o.map.scrollWheelZoom.disable();
    }
  }
}
</script>
