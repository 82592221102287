<template>
<v-app>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(item,i) in content"
      :key="i"
    >
      <v-expansion-panel-header class="h5 mb-0">
        <span class="accordion__numbers h1 mb-0" v-if="style == 'numbers'">
          {{ numberUp(i) }}
        </span>

        <span class="accordion__numbers h1 mb-0" v-if="style == 'jobs'">
          <i class="fas fa-user text-primary"></i>
        </span>
        {{ item.title }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div v-html="item.content"></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-app>
</template>

<script>
export default {
  name: "Accordion",

  computed: {
    content() {
      return this.$root.content;
    },

    style() {
      return this.$root.style;
    }
  },

  methods: {
    numberUp(number) {
      return number + 1;
    }
  }
}
</script>
