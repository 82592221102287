import Immoapp from "../components/immoapp/Immoapp";
import ImmoappLoader from "../misc/ImmoappLoader";

export default function() {
  const element = document.querySelector("#vue-immoapp");
  if (!element) {
    return;
  }

  ImmoappLoader(element, Immoapp, {
    store: true,
    router: true
  });
}
