import Vue from "vue";
import ImmoExposeMap from "../components/map/ImmoExposeMap.vue";

export default () => {
  const elements = document.querySelectorAll(".vue-expose-map");

  if (elements) {
    elements.forEach((el) => {
      let entry = ImmoExposeMap;

      new Vue({
        el,

        computed: {
          mapZoom() {
            let zoom = 10;
            if (el.dataset.zoom) {
              zoom = el.dataset.zoom;
            }

            return zoom;
          },

          mapCenter() {
            let center = {
              lat: this.$yeti.company().lat,
              lng: this.$yeti.company().lng
            };
            if (el.dataset.center) {
              center = JSON.parse(el.dataset.center);
            }

            return center;
          }
        },

        render: h => h(entry)
      })
    });
  }
};
