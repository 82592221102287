import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".swiper-container");
  if (!elms) {
    return;
  }

  deps.load("swiper").then((swiper) => {
    const Swiper = swiper.Swiper;

    elms.forEach((elm) => {
      let config = {};

      if (elm.dataset.config) {
        config = JSON.parse(elm.dataset.config);
        elm.removeAttribute("data-config");
      }

      if (elm.dataset.thumbs) {
        const thumbsElm = document.querySelector(elm.dataset.thumbs);
        let thumbsConfig = JSON.parse(thumbsElm.dataset.config);
        thumbsConfig = {...thumbsConfig, ...{
          el: elm.dataset.thumbs
        }};

        config = {...config, ...{
          thumbs: {
            swiper: thumbsConfig
          }
        }};
      }

      new Swiper(elm, config);
    });
  });
}
