<template>
<div :class="{ 'v-overlay--active': loading, 'v-overlay-nmodal': !modal,  }" class="v-overlay">
  <div class="v-overlay__scrim"></div>

  <div v-if="loading" class="v-overlay__content w-100 h-100 align-items-center d-flex justify-content-center">
    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
  </div>
</div>
</template>

<script>
export default {
  name: "AppLoading",

  props: {
    modal: Boolean
  },

  computed: {
    loading() {
      return this.$store.state.loading || !this.$store.state.appLoaded;
    }
  }
};
</script>

