<template>
<v-app>
 <v-dialog
      v-model="dialog"
    >
    <v-card>
      <v-card-title
        class="headline w-100"
        primary-title
      >
          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text class="text-center">
        <p class="is-style-subline mb-1">{{ content.position }}</p>
        <p class="h2">{{ content.name }}</p>

        <div class="wp-block-columns is-style-12-12">
          <div class="wp-block-column">
            <div :data-bg="content.image.url" class="lazyload team__image"></div>
          </div>

          <div class="wp-block-column">
            <div v-html="content.content"></div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <button
          class="btn btn-link"
          @click="dialog = false"
        >
          Fenster Schließen
        </button>
      </v-card-actions>
    </v-card>
</v-dialog>
</v-app>
</template>

<script>
export default {
  name: "Modal",

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    name() {
      return this.$root.name;
    },

    content() {
      return this.$root.content;
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#" + this.name + "']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.dialog = true;
      });
    }
  }
}
</script>
