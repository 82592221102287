import ImmoappLoader from "../misc/ImmoappLoader";
import FinderApp from "../components/immoapp/FinderApp";

export default function() {
  const element = document.querySelector("#vue-immoapp-finder");
  if (!element) {
    return;
  }

  ImmoappLoader(element, FinderApp, {
    props: {
    }
  });
}
