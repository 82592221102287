<template>
<div class="immoapp__login">
  <v-snackbar v-if="internetError" v-model="internetError" :bottom="true" :timeout="0">{{ $t("Fehler bei der Netzwerkverbindung") }}</v-snackbar>
  <v-snackbar v-if="loggedOut" v-model="loggedOut" :bottom="true" :timeout="0">{{ $t("Sie wurden abgemeldet.") }}&nbsp;<a @click="refresh()">{{ $t("Seite neu laden.") }}</a></v-snackbar>

  <slot v-if="loggedIn"></slot>

  <div v-else>
    <v-form @submit.prevent="login" class="text-center">
      <h1>{{ $t("Anmeldung") }}</h1>

      <div class="row">
      <div class="col-24 col-lg-12 offset-lg-6">
        <p>{{ $t("Geben Sie Ihre E-Mail-Adresse ein und folgen Sie den") }}<br/>
        {{ $t("Anweisungen in der E-Mail") }}.</p>

        <v-alert :value="error" color="error">{{ $t("Es ist ein Fehler aufgetreten.") }}</v-alert>
        <v-alert :value="sent === false" color="error">{{ $t("Bitte überprüfen Sie Ihre E-Mail-Adresse.") }}</v-alert>

        <v-alert v-if="sent === true" color="success">{{ $t("Bitte überprüfen Sie Ihr E-Mail-Postfach.") }}</v-alert>

        <div class="d-flex immoapp__inputgroup" v-else>
          <v-text-field filled outlined v-model="email" name="email" label="E-Mail-Adresse" type="text"></v-text-field>
          <button class="btn btn-primary btn-search" type="submit" @click.prevent="login"><i class="fas fa-chevron-right"></i></button>
        </div>
      </div>
      </div>
    </v-form>
  </div>
</div>
</template>

<script>
import depsloader from "../../depsloader";

export default {
  name: "ImmoappLogin",

  data: () => ({
    email: "",
    error: false,
    sent: null
  }),

  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },

    hasBeenLoggedIn() {
      return this.$store.state.auth.hasBeenLoggedIn;
    },

    internetError() {
      return this.$store.state.auth.internetError;
    },

    loggedOut() {
      return this.$store.state.auth.loggedOut;
    }
  },

  created() {
    this.$store.dispatch("auth/start");
  },

  beforeDestroy() {
    this.$store.dispatch("auth/destroy");
  },

  methods: {
    async login() {
      this.$store.commit("loading", true);
      depsloader.load("axios").then((axios) => {
        axios({
          method: "post",
          url: "/wp-json/sw-immoapp/v1/auth/login",
          data: {
            email: this.email
          }
        }).then((res) => {
          this.sent = res.data.sent;
        }).catch(() => {
          this.error = true;
        }).finally(() => {
          this.$store.commit("loading", false);
        });
      });
    },

    refresh() {
      window.location.reload();
    }
  },
};
</script>
