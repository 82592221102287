<template>
<v-tabs
  v-model="tab"
  class="elevation-0"
  :centered="true">
  <v-tabs-slider></v-tabs-slider>

  <v-tab
    v-for="i in tabs"
    :key="i.name"
    :href="`#t${i.name}`">
      {{ i.title }}
  </v-tab>

  <v-tab-item
    v-for="i in tabs"
    :key="i.name"
    :value="'t' + i.name">

    <v-card :outlined="true">
      <v-card-text class="readmore">
        <input type="checkbox" class="readmore__state" :id="i.name" />

        <div class="readmore__wrap">{{ i.value }}</div>

        <label :for="i.name" class="readmore__trigger"></label>
      </v-card-text>
    </v-card>

  </v-tab-item>
</v-tabs>
</template>

<script>
export default {
  name: "ExposeTab",

  data() {
    return {
      tab: null
    }
  },

  mounted() {
    for (const tab of this.tabs) {
      const buttons = document.querySelectorAll("a[href = '#t" + tab.name + "']:not([role='tab'])");
      if (buttons.length > 0) {
        for (const btn of buttons) {
          btn.addEventListener("click", () => {
            this.tab = 't' + tab.name;
            this.$el.scrollIntoView();
          });
        }
      }
    }
  },

  computed: {
    tabs() {
      return this.$root.tabs;
    }
  }
}
</script>
