/*
Achtung! Die range-Werte können bei den einzelnen Kategorien nicht
unterschiedlich sein. Das Feld wird intern nur 1x gebaut.
*/

export default {
  categoryList: [
    {
      name: "haeuser",
      fieldList: [
        { var: "price.value", range: "max" },
        { var: "area.livingSpace", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.numberRooms", range: "min" },
      ]
    },
    {
      name: "grundstuecke",
      fieldList: [
        { var: "price.value", range: "max" },
        { var: "area.plotArea", range: "min" },
      ]
    },
    {
      name: "wohnungen",
      fieldList: [
        { var: "price.value", range: "max" },
        { var: "area.livingSpace", range: "min" },
        { var: "area.numberRooms", range: "min" },
      ]
    },
    {
      name: "hallen-lager-produktion",
      fieldList: [
        { var: "area.plotArea", range: "min" },
        { var: "area.officeSpace", range: "min" },
        { var: "area.floorSpace", range: "min" },
        { var: "area.totalArea", range: "min" },
      ]
    },
    {
      name: "bueros-praxen",
      fieldList: [
        { var: "area.officeSpace", range: "min" },
        { var: "area.numberRooms", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.totalArea", range: "min" },
      ]
    },
    {
      name: "einzelhandel",
      fieldList: [
        { var: "area.warehouseSpace", range: "min" },
        { var: "area.retailSpace", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.totalArea", range: "min" },
      ]
    },
    {
      name: "gastronomie",
      fieldList: [
        { var: "area.numberRooms", range: "min" },
        { var: "area.totalArea", range: "min" },
      ]
    },
    {
      name: "land-forstwirtschaft",
      fieldList: [
        { var: "area.floorSpace", range: "min" },
        { var: "area.numberRooms", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.totalArea", range: "min" },
      ]
    },
  ]
};
