<template>
<v-app>
<div class="immoapp__finder" data="app">
  <div class="wp-block-group has-light-background-color has-background is-style-space-small">
    <div class="wp-block-group__inner-container">
    <v-form @submit.prevent="search">

      <v-row
        class="mt-4"
        no-gutters
      >
        <v-col
          cols="24"
          lg="4"
          md="12">
          <v-select
            :items="marketingTypes"
            label="Käufer & Mieter"
            v-model="marketingType"
            filled
            flat
            clearable
            attach
          ></v-select>
        </v-col>

        <v-col
          cols="24"
          lg="4"
          md="12">
          <v-select
            :items="categories"
            v-model="category"
            label="Kategorie"
            :return-object="true"
            filled
            flat
            clearable
            attach
          ></v-select>
        </v-col>

        <v-col
          cols="24"
          md="8"
          lg="8">
          <geocoder-autocomplete
            :hide-details="true"
            v-model="addressModel"
            @change="updateGeo">
            </geocoder-autocomplete>
        </v-col>

        <v-col
          cols="24"
          md="8"
          lg="4">
          <inquiry-field-input
            label="Preisvorstellung"
            type="number"
            :value="price"
            @update="updatePrice"
            unit="€"
            :filled="true">
          </inquiry-field-input>
        </v-col>

        <v-col
          cols="24"
          md="8"
          lg="4">
          <button type="submit" class="btn btn-block btn-primary btn-finder">Suchen</button>
        </v-col>
      </v-row>

    </v-form>
    </div>
  </div>

  <div ref="results" v-if="results">

    <div class="wp-block-group is-style-space-big">
      <div class="wp-block-group__inner-container text-center">
          <p class="claim text-primary">
              <animated-number
                :value="results.length"
                :round="1"
              />
          </p>
          <p v-if="results.length == 1">Interessent gefunden</p>
          <p v-else>Interessenten gefunden</p>
      </div>
    </div>

    <hr class="immoapp__finder__divider mb-0" />

    <div class="wp-block-group is-style-space-big" v-if="results.length">
      <div class="wp-block-group__inner-container ">
          <p class="h2 mt-0 mb-8 text-center">Ihre Interessenten</p>


        <p class="h4 text-center" v-if="results.length > 12">Es wurden viele potentielle Interessenten gefunden. <br/>
          Bitte verfeinern Sie Ihre Suche um den optimalen Interessenten zu finden.</p>

        <div class="row p-t-lg">
          <template v-for="item in resultsShort">
            <div class="col-24 col-md-12 col-lg-8" :key="item.id">
              <div class="immolisting__wrapper savedsearch savedsearch--found">
                <div class="savedsearch__content savedsearch__content--100">
                  <h3>
                    <span v-if="item.maxPrice != 0 || item.minPrice != 0">
                        <span v-if="item.minPrice != 0">
                         <span v-if="item.maxPrice == 0">{{ $t("fieldMin") }}</span> {{ $n(item.minPrice, "currency") }}
                        </span>
                        <span v-if="item.minPrice != 0 && item.maxPrice != 0">
                          &ndash;
                        </span>
                        <span v-if="item.maxPrice != 0">
                          <span v-if="item.minPrice == 0">{{ $t("fieldMax") }}</span> {{ $n(item.maxPrice, "currency") }}
                        </span>
                    </span>
                    <span v-else>
                      Keine Angabe
                    </span>
                  </h3>

                  <div class="immolisting__wrapper">
                    <ul class="savedsearch__facts">
                      <li>
                          <span class="fas fa-home"></span>

                          <title-field :category="getCategory(item.categoryId)" :marketingType="item.marketingType"></title-field>
                      </li>

                      <li>
                          <span class="fas fa-map-marker-alt"></span>
                          <span v-if="item.city">
                            <span v-if="item.zip">{{ item.zip }} </span>
                            <span v-if="item.city && item.district">{{ item.city }}–{{ item.district }}</span>
                            <span v-else-if="item.city">{{ item.city }}</span>
                          </span>
                          <span v-else>
                            Keine Angabe
                          </span>
                          <span>
                            <br>
                            <span v-if="item.radius != 0">
                            {{ $t("Umkreis") }}: {{ item.radius }}&nbsp;km
                            </span>
                            <span v-else>
                            {{ $t("Umkreis") }}: Keine Angabe
                            </span>
                          </span>
                      </li>

                      <li>
                        <span class="fas fa-hammer"></span>
                        <span v-if="item.minConstructionYear != 0 && item.maxConstructionYear != 0">
                          Baujahr: {{ item.minConstructionYear }} – {{ item.maxConstructionYear }}
                        </span>
                        <span v-else-if="item.minConstructionYear != 0">
                          Baujahr: {{ $t("fieldMin") }} {{ item.minConstructionYear }}
                        </span>
                        <span v-else-if="item.maxConstructionYear != 0">
                          Baujahr: {{ $t("fieldMax") }} {{ item.maxConstructionYear }}
                        </span>
                        <span v-else>
                          Baujahr: -
                        </span>
                      </li>
                    </ul>

                    <ul class="immolisting__iconfields">
                      <li v-if="item.minNumberRooms">
                          <span class="value" v-if="item.minNumberRooms">
                          <span class="fas fa-bed"></span> {{ $t("fieldMin") }}&nbsp;{{ $n(item.minNumberRooms, "decimal") }}</span>
                          <span class="value" v-else-if="item.maxNumberRooms">
                          <span class="fas fa-bed"></span> {{ $t("fieldMax") }}&nbsp;{{ $n(item.maxNumberRooms, "decimal") }}</span>
                          <span class="key">Zimmer</span>
                      </li>
                      <li v-if="item.minLivingSpace">
                          <span class="value" v-if="item.minLivingSpace">
                          <span class="fas fa-vector-square"></span> {{ $t("fieldMin") }}&nbsp;{{ $n(item.minLivingSpace, "decimal") }} m²</span>
                          <span class="value" v-else-if="item.maxLivingSpace">
                          <span class="fas fa-vector-square"></span> {{ $t("fieldMax") }}&nbsp;{{ $n(item.maxLivingSpace, "decimal") }} m²</span>
                          <span class="key">Wohnfläche</span>
                      </li>
                      <li v-if="item.minPlotArea">
                          <span class="value" v-if="item.minPlotArea">
                          <span class="fas fa-street-view"></span> {{ $t("fieldMin") }}&nbsp;{{ $n(item.minPlotArea, "decimal") }} m²</span>
                          <span class="value" v-else-if="item.maxPlotArea">
                          <span class="fas fa-street-view"></span> {{ $t("fieldMax") }}&nbsp;{{ $n(item.maxPlotArea, "decimal") }} m²</span>
                          <span class="key">Grundstück</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <a href="#anbieten" class="btn btn-primary btn-block">Immobilie anbieten</a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

  </div>
  <div v-else>
    <hr class="immoapp__finder__divider mb-0" />
  </div>
</div>
<div id="anbieten"></div>
</v-app>
</template>

<script>
import GeocoderAutocomplete from "./utils/GeocoderAutocomplete";
import InquiryFieldInput from "./contact/InquiryFieldInput";
import config from "./contact/ConfigSearchInquiry";
import depsloader from "../../depsloader";
import AnimatedNumber from "animated-number-vue";
import TitleField from "./fields/TitleField";

export default {
  name: "FinderApp",

  props: [  ],

  components: {
    GeocoderAutocomplete,
    InquiryFieldInput,
    TitleField,
    AnimatedNumber
  },

  data: () => ({
    marketingType: "",
    category: "",
    price: null,
    loading: false,
    results: null,
    addressModel: "",
    marketingTypes: [
      {
        text: 'Käufer',
        value: "BUY"
      },
      {
        text: 'Mieter',
        value: "RENT"
      }
    ]
  }),

  computed: {
    categories() {
      const list = [];

      config.categoryList.forEach(c => {
        c.text = this.$t(c.text);
        if (("marketingType" in c && c.marketingType == this.marketingType) || !("marketingType" in c)) {
          list.push(c);
        } else if (!this.marketingType) {
          list.push(c);
        }
      });

      return list;
    },

    resultsShort() {
      return this.results.slice(0, 12);
    },
  },

  mounted() {

  },

  methods: {
    getCategory(id) {
      let category = {
        text: this.$t("Alles")
      };

      config.categoryList.forEach(c => {
        if (c.id == id) {
          category = c;
        }
      });

      return category;
    },

    updateGeo(geo) {
      this.addressModel = geo;
    },

    updatePrice(price) {
      this.price = price;
    },

    search() {
      this.categoryId = null;
      if (this.category) {
        this.categoryId = this.category["id"];
      }

      const data = {
        categoryId: this.categoryId,
        marketingType: this.marketingType,
        price: this.price,
        address: this.addressModel
      };

      let axiosConfig = null;
      let axiosData = data;
      this.loading = true;
      this.results = [];

      depsloader.load("axios").then((axios) => {
        const headers = {}

        axios({
          method: "post",
          url: "/wp-json/sw-immoapp/v1/saved-searches/public-finder",
          headers: headers,
          data: axiosData,
          config: axiosConfig
        }).then((res) => {
          if (res.data && res.data.length > 0) {
            this.results = res.data;
            this.$refs.results.scrollIntoView()
          }

        }).catch(() => {
          this.error = true;
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        });
      });
    }
  }
};
</script>
