import ImmoappLoader from "../misc/ImmoappLoader";
import SavedSearchStandaloneApp from "../components/immoapp/contact/SavedSearchStandaloneApp";

export default function() {
  const element = document.querySelector("#vue-immoapp-savedsearch");
  if (!element) {
    return;
  }

  ImmoappLoader(element, SavedSearchStandaloneApp, {
    store: true
  });
}

