<template>
  <div>
    <a
      href="#"
      class="testimonials-form__star"
      v-for="(star, idx) in stars"
      :key="idx"
      @click.prevent="change(idx, $event)"
    >
      <i class="far fa-star" :class="{ 'checked': star.checked }"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "Rating",

  data() {
    return {
      stars: []
    };
  },

  created() {
    for (let i = 0; i < 5; i++) {
      this.stars.push({
        checked: true
      });
    }

    // for (let i = 3; i < 5; i++) {
    //   this.stars.push({
    //     checked: false
    //   });
    // }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    change(idx) {
      for (let i = 0; i < this.stars.length; i++) {
        if (i < idx + 1) {
          this.stars[i].checked = true;
        } else {
          this.stars[i].checked = false;
        }
      }
    },

    mapValue() {
      let val = 0;
      for (let i = 0; i < this.stars.length; i++) {
        if (this.stars[i].checked) {
          val++;
        }
      }

      this.$root.contactForm.dataFields.rating = val;
    }
  }
};
</script>
