import Vue from "vue";
import Map from "../components/map/Map.vue";

export default () => {
  const elements = document.querySelectorAll(".vue-map");

  if (elements) {
    elements.forEach((el) => {
      let entry = Map;

      new Vue({
        el,

        computed: {
          mapZoom() {
            let zoom = 10;
            if (el.dataset.zoom) {
              zoom = el.dataset.zoom;
            }

            return zoom;
          },

          mapCenter() {
            let center = [];
            if (el.dataset.center) {
              center = JSON.parse(el.dataset.center);
            }

            return center;
          },

          mapMaxZoom() {
            let zoom = null;
            if (el.dataset.maxZoom) {
              zoom = el.dataset.maxZoom;
            }

            return zoom;
          },
        },

        render: h => h(entry)
      })
    });
  }
};
