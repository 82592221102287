<template>
  <standard-map v-model="map" :value="mapCenter" :zoom="mapZoom" @initialized="onMapLoad"></standard-map>
</template>

<script>
import StandardMap from "../StandardMap";

export default {
  name: "Map",

  components: {
    StandardMap
  },

  data() {
    return {
      map: null
    }
  },

  computed: {
    mapCenter() {
      return this.$root.mapCenter;
    },

    mapZoom() {
      return this.$root.mapZoom;
    },

    mapMaxZoom() {
      return this.$root.mapMaxZoom;
    }
  },

  methods: {
    onMapLoad(o) {
      o.L.marker(this.mapCenter, {
        alt: "Eintrag auf der Karte"
      }).addTo(o.map);

      this.map = this.mapCenter;
      o.map.scrollWheelZoom.disable();

      if (this.mapMaxZoom) {
        o.map.setMaxZoom(11);
        o.map.touchZoom.disable();
      }
    }
  }
}
</script>
