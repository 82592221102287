<template>
<span v-if="category && marketingType">
  {{ categoryMarketingTypeText }}
</span>
<span v-else-if="category">
  {{ $t(category.text) }}
</span>
<span v-else>
  {{ marketingTypeText }}
</span>
</template>

<script>
export default {
  name: "TitleField",

  props: [
    "category",
    "marketingType"
  ],

  computed: {
    categoryMarketingTypeText() {
      return this.$t("categoryMarketingType" + this.marketingType, { category: this.$t(this.category.text) });
    },

    marketingTypeText() {
      return this.$t("marketingType" + this.marketingType);
    }
  }
}
</script>
