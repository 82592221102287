import Vue from "vue";

export default function() {
  const element = document.querySelector("#immo-expose");
  if (!element) {
    return;
  }

  if (!element.dataset.cid) {
    return;
  }

  if (!Vue.prototype.$yeti) {
    return;
  }

  Vue.prototype.$yeti.trackEvent("immo", "object-view", element.dataset.cid);
}
