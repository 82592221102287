import ImmoappLoader from "../misc/ImmoappLoader";
import ExposeContactStandaloneApp from "../components/immoapp/contact/ExposeContactStandaloneApp";

export default function() {
  const element = document.querySelector("#vue-immoapp-exposecontact");
  if (!element) {
    return;
  }

  ImmoappLoader(element, ExposeContactStandaloneApp, {
    store: true,
    props: {
      exposeId: element.dataset.id,
      customObjectId: element.dataset.cid
    }
  });
}

