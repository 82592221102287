<template>
<div>
  <v-row>
    <v-col cols="24" sm="12">
      <v-radio-group :value="objectType" @change="setObjectType" row>
        <v-radio :label="$t('Wohnen')" value="LIVING" off-icon="fal fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio :label="$t('Gewerbe')" value="COMMERCIAL" off-icon="fal fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="24" sm="12" class="text-right">
      <v-radio-group v-if="inquiryType == 'lead'" :value="marketingType" @change="setMarketingType" row>
        <v-radio :label="$t('Verkaufen')" value="BUY" off-icon="fal fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio :label="$t('Vermieten')" value="RENT" off-icon="fal fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
      <v-radio-group v-else :value="marketingType" @change="setMarketingType" row>
        <v-radio :label="$t('Kaufen')" value="BUY" off-icon="fal fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio :label="$t('Mieten')" value="RENT" off-icon="fal fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>


  <v-select
    v-if="!icons"
    :value="category"
    :items="categories"
    item-text="displayText"
    return-object
    :label="$t('Kategorie')"
    @change="setCategory"
    attach
    filled
    flat
  ></v-select>

  <div v-if="icons" class="immoapp__wizard__icons">

    <div v-for="(cat, k) in categories" :key="k">
      <div @click="iconClick(cat)" class="immoapp__wizard__iconbox" v-bind:class="{ active: cat == category }">
        <i :class="'immoapp__wizard__icon immoapp__wizard__icon--' + cat.text"></i>
        <span>{{ cat.displayText }}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "InquiryCategories",

  props: {
    marketingType: String,
    category: Object,
    objectType: String,
    categoryList: Array,
    inquiryType: String,
    icons: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    categories() {
      const list = [];

      this.categoryList.forEach(c => {
        c.displayText = this.$t(c.text);
        if ("marketingType" in c && c.marketingType == this.marketingType) {
          list.push(c);
        } else if ("objectType" in c && c.objectType == this.objectType) {
          list.push(c);
        }
      });

      return list;
    }
  },

  methods: {
    setObjectType(val) {
      this.$emit("objectType", val);
    },

    setMarketingType(val) {
      this.$emit("marketingType", val);
    },

    setCategory(val) {
      this.$emit("category", val);
    },

    iconClick(val) {
      this.setCategory(val);
      this.$emit("next");
    }
  }
};
</script>
