<template>
<v-select
  :label="mylabel"
  :id="id"
  v-model="val"
  :items="items"
  :error="error"
  :error-messages="error"
  attach
  filled
  >
</v-select>
<!--
<div class="form-label-group" :class="{ 'is-invalid': error  }">
    <b-form-select ref="select" :id="id" v-model="val" :options="items" required></b-form-select>
    <label :for="id" @click="focus()">{{ mylabel }}</label>


    <small v-if="error" class="form-text text-danger">{{ error }}</small>
</div>
-->
</template>

<script>
export default {
  name: "ContactSelect",

  props: [
    "name",
    "group",
    "values",
    "label",
    "placeholder"
  ],

  data() {
    return {
      val: null
    };
  },

  mounted() {
    if (this.group == "contact") {
      this.val = this.$root.contactForm.contact[this.name];
    }
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  computed: {
    invalid() {
      return this.$root.contactForm.errors.indexOf(this.name) > -1;
    },

    required() {
      return this.$root.contactForm.isRequired(this.name);
    },

    mylabel() {
      if (this.required) {
        return `${this.label}*`;
      }

      return this.label;
    },

    error() {
      if (this.invalid) {
        return "Pflichtfeld";
      }

      return null;
    },

    items() {
      const options = [];

      this.values.split(",").forEach((v) => {
        let text = v;
        let value = v;
        if (v.indexOf("|") != -1) {
          const p = v.split("|");
          text = p[0].trim();
          value = p[1].trim();
        }

        options.push({
          text,
          value
        });
      });

      return options;
    },

    id() {
      return `contact__${this.name}`;
    }
  },

  methods: {
    /*
    focus() {
      this.$refs.select.$el.click();
    },
    */

    mapValue() {
      if (this.group == "contact") {
        this.$root.contactForm.contact[this.name] = this.val;
      }
    }
  }
};
</script>
