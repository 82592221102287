<template>
<div class="testimonials">

  <div v-if="visible" class=" testimonials-form">
  <v-app class="ast-container">
    <contact-form :name="name" buttonText="Bewertung abgeben" :requiredFields="requiredFields">
      <testimonial-form></testimonial-form>
      <template slot="disclaimer">
        Mit der Erfassung, Speicherung, Verwendung und Veröffentlichung meiner Bewertung bin ich einverstanden.
        Hinweis: Sie können Ihre Einwilligung jederzeit widerrufen.
      </template>
    </contact-form>
  </v-app>
  </div>
</div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import TestimonialForm from "./TestimonialForm.vue";

export default {
  name: "TestimonialApp",

  components: {
    ContactForm,
    TestimonialForm
  },

  data() {
    return {
      name: "testimonials",
      visible: true
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  },

  methods: {
    openForm() {
      this.visible = !this.visible;

      this.$nextTick(() => {
        let element = this.$el;
        let offsetTop = element.offsetTop - 100;

        window.scrollTo({top: offsetTop, behavior: 'smooth'});
      });
    }
  }
};
</script>
