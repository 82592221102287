import deps from "./depsloader";

const yeti = {
    isTouchDevice: false,
    trackingEnabled: false,
    downlink: navigator.connection && navigator.connection.downlink,

    setupTracking: function() {
      const urlSearchParams = new URLSearchParams(window.location.href);

      const campaign = urlSearchParams.get("campaign");
      const keyword = urlSearchParams.get("keyword");

      if (campaign || keyword) {
        deps.load("js-cookies").then((Cookies) => {
          Cookies.set("swtracking", JSON.stringify({
            campaign,
            keyword
          }));
        });
      }
    },

    isSlowInternet: function() {
      return !this.downlink || this.downlink < 3;
    },

    eventCache: [],
    trackEvent: function(category, action, label) {
      // Achtung!! Wird vom neuen Cookie Hinweis überschrieben

      if (this.trackingEnabled && window.ga ) {
        window.ga("send", "event", category, action, label);

        if (category == "conversion") {
          let event = {
            hitType: "event",
            eventCategory: "conversion_gads_query"
          };

          deps.load("js-cookies").then((Cookies) => {
            const swtracking = Cookies.get("swtracking");
            if (swtracking) {
              const data = JSON.parse(swtracking);

              if ("campaign" in data) {
                event["eventAction"] = data["campaign"];
              }

              if ("keyword" in data) {
                event["eventLabel"] = data["keyword"];
              }

              window.ga("send", event);
            }

            const googleAds = this.googleAds();
            if (googleAds && googleAds.conversionEvent && window.gtag) {
              window.gtag("event", "conversion", {"send_to": googleAds.conversionEvent});
            }
          });
        }
      } else {
        this.eventCache.push({
          category: category,
          action: action,
          label: label
        });
      }
    },

    sendEvents: function() {
      let events = this.eventCache;
      this.eventCache = [];
      events.forEach(event => {
        this.trackEvent(event.category, event.action, event.label);
      })
    },

    enableTracking: function() {
      this.trackingEnabled = true;
      this.sendEvents();
    }
}

const tmp = DOLLAR_YETI_GLOBAL_OVERWRITE; // eslint-disable-line

Object.keys(tmp).forEach(k => {
  yeti[k] = tmp[k];
});

window.addEventListener("touchstart", function onFirstTouch() {
    yeti.isTouchDevice = true;
    window.removeEventListener("touchstart", onFirstTouch, false);
}, false);


let body = document.querySelector("body");

function handleNavScroll() {
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if (scrollPosY > 70) {
    body.classList.add("has-scrolled");
  } else {
    body.classList.remove("has-scrolled");
  }
}

window.addEventListener("scroll", () => {
  handleNavScroll();
}, false);

handleNavScroll();

export default yeti;
